/**
 * Mixin for single CV section
 * 
 * 1) Gets sections Items - Eductaion, Skills, etc...
 * 2) Updates section Items
 * 3) Deletes item
 * 4) Add new item to the list
 * 
 * @prop items -> will store specific section items -> defined in component
 * @prop candidate -> current candidate which CV is beeing updated
 * @prop updateService -> axios service method for updating section
 * @prop getService -> axios service method for getting sections items
 * 
 * @method getNewRecords -> method for preparing data for storing (needs to be object which key is sections name for example {'languages': []})
 * @method getSingleItem -> method for getting empty blueprint of single section item () cant be defined as prop because then reference will be pushed into list
 */
import FormErrors from "@/components/widgets/FormErrors";

export default {
    props: {
        _candidate: {
            default: null
        }
    },
    components: {
        FormErrors
    },
    methods: {
        add() {
            this.items.push(this.getSingleItem());
        },
        deleteItem(index) {
            if (this.items[index]) {
                this.items.splice(index, 1);
            }
        },
        save() {
            const data = this.getNewRecords();

            this.updateService(this.candidate.id, data).then(res => {
                const { success, message } = res.data;
                if (success) {
                    this.errors = [];
                    console.log(message);
                }
            }).catch(err => {
                const { errors } = err.response.data;
                if (errors) {
                    this.errors = errors;
                }
            })
        },
        getItems() {
            this.getService(this.candidate.id).then(res => {
                const { data } = res.data;
                if (Array.isArray(data) && data.length) {
                    this.items = data;
                }
            }).catch(err => {
                console.log(err);
            })
        }
    },
    mounted() {
        this.getItems()
    },
    computed: {
        /**
         * Candidate can be passed to component via prop, otherwise current users id will be taken
         */
        candidate() {
            if (this._candidate) {
                return this._candidate.id;
            }
            return this.$store.getters.user;
        }
    }
}